import {messageDatabase } from '../../../Database';
import { Component, ViewEncapsulation, Input, ViewChild, ElementRef } from '@angular/core';
import { RouterModule, ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { setTheme } from 'ngx-bootstrap/utils';
import {MatTableDataSource} from '@angular/material/table';
import { currenciesObject, GuestElement, messageObject, messageRecipientDocument } from '../../../../interfaces/interfaces';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessagesService } from '../../../services/messages/messages.service';
import { messageRecipientStatusDataObject } from '../../../../../functions/src/interfaces/interfaces';
import { MatPaginator, MatSort } from '@angular/material';

declare let moment: any;
export interface mergeVariableObject {
  value?: string;
  key?: string;
  description: string;
  subVariables?: Array<mergeVariableObject>;
}

// export interface msgObject {
//   sender: {
//     firstname: string,
//     lastname: string
//   }
//   id: string,
//   date: number,
//   message: {
//     smsMessage: string,
//     recipients: Array<any>
//   },
//   messageType: string
// }

export interface smsCalc {
    maxLetters: number,
    letterCount: number,
    SMSCount: number,
    SMSTotal: number,
    price: number,
    error: any,
}

export interface clockworkBalance {
  saldo: string
}

@Component({
  selector: 'messageDetail',
  templateUrl: './messageDetail.template.html',
  styleUrls: ['./messageDetail.style.scss'],
  encapsulation: ViewEncapsulation.None
})
export class messageDetail {
  @ViewChild('messageDetailModal',{static: true}) public messageDetailModal
  @ViewChild('messageEditor',{static: false}) messageEditor: ElementRef;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @Input() selectedGuests: Array<any> = [];
  selectedGuestsCount: number = 0

  subscriptionArray: Array<any> = []

  searchText: string = ''
  displayedColumns: string[] = ['status', 'name', 'to'];

  message: messageObject = {
    sender: {
      firstname: '',
      lastname: ''
    },
    id: '',
    date: new Date(),
    message: {
      smsMessage: '',
    },
    messageType: 'sms'
  }
  recipients: MatTableDataSource<messageRecipientDocument> = new MatTableDataSource([]);;
  recipientsTable = new MatTableDataSource([]);
  recipientCount = {
    sending: 0, //queued and sending
    success: 0,
    error: 0,
    rejected: 0,
    opened: 0
  }
  
  hideRecipients = false;
  working = false;

  newMessageBool: boolean = true;
  filterStatus: string;

  //SMS
  SMScalc: any = {
    maxLetters: 459,
    letterCount: 0,
    SMSCount: 0,
    SMSTotal: 0,
    price: 0,
    error: false
  }
  gatewayapiToken = 'ijlj9P_UQvCF9ml-BfNdaR9cQjdYKllDYsPTk8qmyO0TusWKeXNyoFh-WnjzTMXz'
  currencies: currenciesObject = {}
  smsBalance: {saldo?: string, balance?: {credit?: number, currency?: String}, prices?: Array<{country: string, country_name: string, eur: number, prefix: number}>} = {
    prices: [{
      country: 'NO',
      country_name: 'Norway',
      eur: 0.039,
      prefix: 47
    }]
  }

  button: {buttonText?: string, magicLink?: string} //Om det skal vises knapp i epost eller ikke
  english: boolean = false

  mergeFields: Array<mergeVariableObject> = [{
    description: 'Fornavn',
    value: '#FNAVN#',
    key: 'firstname'
  },{
    description: 'Etternavn',
    value: '#ENAVN#',
    key: 'lastname'
  },{
    description: 'Kallenavn',
    value: '#KNAVN#',
    key: 'nickName'
  },{
    description: 'Fullt navn',
    value: '#NAVN#',
    key: 'fullname'
  },{
    description: 'Navn i gruppen',
    value: '#GRUPPENAVN#',
    key: 'groupName'
  },{
    description: 'InvitasjonsID',
    value: '#INVID#',
    key: 'invitationId'
  },{
    description: 'Invitasjonslink',
    value: '#INVLINK#',
    key: 'invlink'
  },{
    description: 'Du / Dere',
    value: '#DUDERE#',
    key: 'dudere'
  },{
    description: 'Deg / Dere',
    value: '#DEGDERE#',
    key: 'degdere'
  }]

  editorConfig_Epost = {
    'height': '200px',
    'minHeight': '200px',
    "toolbar": [
      ["bold", "italic", "underline"],
      ["fontName", "fontSize", "color"],
      ["justifyLeft", "justifyCenter", "justifyRight"],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
    ]
  }  

  columnsToDisplay = ['status', 'name', 'phone'];
  // variableFields = [{
  //   field: 'Fornavn',
  //   variable: '#FNAVN#'
  // },{
  //   field: 'Etternavn',
  //   variable: '#ENAVN#'
  // },{
  //   field: 'Kallenavn',
  //   variable: '#KNAVN#'
  // },{
  //   field: 'Fullt navn',
  //   variable: '#NAVN#'
  // },{
  //   field: 'Navn i gruppen',
  //   variable: '#GRUPPENAVN#'
  // },{
  //   field: 'InvitasjonsID',
  //   variable: '#INVID#'
  // },{
  //   field: 'Invitasjonslink',
  //   variable: '#INVLINK#'
  // },{
  //   field: 'Du / Dere',
  //   variable: '#DUDERE#'
  // },{
  //   field: 'Deg / Dere',
  //   variable: '#DEGDERE#'
  // }];

  count = 0
  caretPos: number = 0
  caretPosEnd
  range
  preCaretRange
  caretElement
  lastKeyCode

  smsMessageStatus: messageRecipientStatusDataObject[] = [
    {apiStatus: 'queued', status: 'queued', color: 'gray', statusText: 'Ingen status', statusMessage: ''},
    {apiStatus: 'sending', status: 'sending', color: 'info', statusText: 'Sender', statusMessage: ''},
    {apiStatus: 'error', status: 'error', color: 'danger', statusText: 'Feil', statusMessage: 'Det skjedde noe feil med levering av meldingen'},
    {apiStatus: 'UNKNOWN', status: 'queued', color: 'gray', statusText: 'Ingen status', statusMessage: ''},
    {apiStatus: 'SCHEDULED', status: 'queued', color: 'info', statusText: 'Planlagt', statusMessage: 'Meldingen er planlagt'},
    {apiStatus: 'BUFFERED', status: 'sending', color: 'info', statusText: 'Sender', statusMessage: 'Meldingen er på vei til mottakeren'},
    {apiStatus: 'ENROUTE', status: 'sending', color: 'info', statusText: 'Sender', statusMessage: 'Meldingen er på vei til mottakeren'},
    {apiStatus: 'DELIVERED', status: 'success', color: 'success', statusText: 'Levert', statusMessage: 'Meldingen er levert!'},
    {apiStatus: 'EXPIRED', status: 'error', color: 'danger', statusText: 'Utløpt', statusMessage: 'Meldingen er utløpt, og kan ikke bli levert.'},
    {apiStatus: 'DELETED', status: 'error', color: 'danger', statusText: 'Slettet', statusMessage: 'Meldingen er slettet fra serveren'},
    {apiStatus: 'UNDELIVERABLE', status: 'error', color: 'danger', statusText: 'Ikke levert', statusMessage: 'Meldingen kunne ikke leveres..'},
    {apiStatus: 'ACCEPTED', status: 'sending', color: 'info', statusText: 'Sender', statusMessage: 'Meldingen er på vei til mottakeren'},
    {apiStatus: 'REJECTED', status: 'rejected', color: 'warning', statusText: 'Avvist', statusMessage: 'Meldingen ble avvist av mobilnettverket'},
    {apiStatus: 'SKIPPED', status: 'error', color: 'danger', statusText: 'Ikke levert', statusMessage: 'Meldingen ble hoppet over av serveren..'},
  ]

  emailMessageStatus: messageRecipientStatusDataObject[] = [
    {apiStatus: 'queued', status: 'queued', color: 'gray', statusText: 'Ingen status', statusMessage: ''},
    {apiStatus: 'sending', status: 'sending', color: 'info', statusText: 'Sender', statusMessage: ''},
    {apiStatus: 'error', status: 'error', color: 'danger', statusText: 'Feil', statusMessage: 'Det skjedde noe feil med levering av meldingen'},
    {apiStatus: 'spam_complaint', status: 'rejected', color: 'warning', statusText: 'Spam', statusMessage: 'Meldingen ble merket som spam av mottakeren...'},
    {apiStatus: 'unsubscribed', status: 'success', color: 'warning', statusText: 'Avmeldt', statusMessage: 'Mottakren har meldt seg av varsler'},
    {apiStatus: 'clicked', status: 'success', color: 'success', statusText: 'Klikket', statusMessage: 'Linken i meldingen ble klikket på'},
    {apiStatus: 'soft_bounced', status: 'rejected', color: 'warning', statusText: 'Avvist', statusMessage: 'Meldingen ble avvist (soft bounce)'},
    {apiStatus: 'hard_bounced', status: 'rejected', color: 'warning', statusText: 'Avvist', statusMessage: 'Meldingen ble avvist (hard bounce)'},
    {apiStatus: 'sent', status: 'success', color: 'info', statusText: 'Sendt', statusMessage: 'Meldingen er sendt!'},
    {apiStatus: 'delivered', status: 'success', color: 'success', statusText: 'Levert', statusMessage: 'Meldingen er levert!'},
    {apiStatus: 'opened', status: 'opened', color: 'success', statusText: 'Åpnet', statusMessage: 'Meldingen er åpnet av mottakeren!'},
  ]

  constructor(
    private afs: AngularFirestore, 
    private route: ActivatedRoute,
    private messagedb: messageDatabase,
    private http: HttpClient,
    private messages: MessagesService) {
      setTheme('bs4'); // or 'bs4'
  }
  
  applyFilter(filterValue: string) {
    this.recipientsTable.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
  
  }

  hideModal() {

  }

  modalOnShown() {

  }

  closeModal() {
    this.messageDetailModal.hide();
  }

  calculateSMS() {
    let maxLetters = 459
    let error = false
    let letterCount = 0
    if (this.message['message'].smsMessage) {
      letterCount = this.message['message'].smsMessage.length
    }
    if (letterCount > 459) {
      letterCount = 459;
      error = true
    }
    let SMSCount = 1
    if (letterCount > 160) {
      SMSCount = 2
    }
    if (letterCount > 320) {
      SMSCount = 3
    }
    // if (letterCount > 459) {
    //   SMSCount = 3
    // }
    let SMSTotal = SMSCount * this.selectedGuestsCount
    let price = SMSCount * 0.60 * this.selectedGuestsCount
    this.SMScalc = {
      maxLetters: maxLetters,
      letterCount: maxLetters - letterCount,
      SMSCount: SMSCount,
      SMSTotal: SMSTotal,
      price: +price.toFixed(2),
      error: error
    }
  }

  newMessage(type) {
    this.working = false;
    this.hideRecipients = false
    // this.getFestivals();
    this.messageDetailModal.show();
    this.newMessageBool = true;
    this.message = {
      id: firebase.firestore().collection('guests').doc().id,
      date: new Date(),
      message: {
        smsMessage: '',
      },
      messageType: type,
      sender: {
        firstname: '',
        lastname: ''
      }
    }
    this.updateCountNumber();
    console.log(this.message)
    console.log(this.selectedGuests)
  }


  updateCountNumber() {
    this.selectedGuestsCount = this.selectedGuests.length;
    this.calculateSMS()
    this.checkSMSBalance();
  }

  setFilterStatus(status: string) {
    console.log(status);
  }

  getRootCurrencies() {
    return this.afs.firestore.collection('root').doc('currencies')
  }

  async checkSMSBalance() {
    let headers = new HttpHeaders({
      'Authorization': 'Basic ' + btoa(this.gatewayapiToken + ':'),
      "Content-Type": "application/json",
    })
    await this.getRootCurrencies().get().then(currenciesSnap => {
      this.currencies = currenciesSnap.data()
      console.log(this.currencies)
    })
    console.log('Sjekker saldo')
    const sub = this.http.get('https://gatewayapi.com/rest/me', {headers}).subscribe(data => {
      console.log(data)
      if (data['credit']) {
        this.smsBalance.balance = data
      } else {
        this.smsBalance.balance = {
          credit: 0,
          currency: 'EUR'
        }
      }
      let currency = 0
      if (this.currencies && this.currencies.EUR) {
        currency = this.currencies.EUR.rates.NOK
      }
      this.smsBalance.saldo = 'Det er ' + (this.smsBalance.balance.credit * currency).toFixed(2) + ' NOK igjen på SMS-kontoen'
      if (sub) sub.unsubscribe()
    }, err => {
        console.log(err)
        sub.unsubscribe()
    })
  }


  // getRecipients() {
  //   this.filterStatus = '';
  //   this.recipients = this.messagedb.getMessage(this.message['id']).collection('recipients').valueChanges().pipe(map(recipients => {
  //     this.recipientCount = {
  //       ENROUTE: [],
  //       DLVRD: [],
  //       UNDELIV: [],
  //       REJECTD: [],
  //       OPEN: []
  //     }      
  //     recipients.forEach(recipient => {
  //       if (recipient['Status'] && recipient.Status == 'DELIVRD') recipient.Status = 'DLVRD'

  //       const errCode = +recipient['ErrCode']
  //       if (recipient['ErrCode'] == '0' || recipient['ErrCode'] == '1') {
  //         recipient['statusColor'] = 'success'
  //       } else if (recipient['ErrCode'] >= 2) {
  //         recipient['statusColor'] = 'danger'
  //       } else if (!recipient['ErrCode']) {
  //         recipient['statusColor'] = 'warning'
  //       }
  //       if (recipient['Status'] == 'UNDELIV') {
  //         recipient['statusMessage'] = 'Ikke levert'
  //       } else if (recipient['Status'] == 'DLVRD') {
  //         recipient['statusMessage'] = 'Levert'
  //       } else if (recipient['Status'] == 'REJECTD') {
  //         recipient['statusMessage'] = 'Avvist'
  //       } else if (recipient['Status'] == 'ENROUTE') {
  //         recipient['statusMessage'] = 'Sender..'
  //       } else if (recipient['Status'] == 'EXPIRED') {
  //         recipient['Status'] = 'UNDELIV'
  //         recipient['statusMessage'] = 'Utgått'
  //       } else if (recipient['Status'] == 'UNKNOWN') {
  //         recipient['Status'] = 'UNDELIV'
  //         recipient['statusMessage'] = 'Ukjent feil'
  //       } else if (recipient['Status'] == 'QUEUED') {
  //         recipient['Status'] = 'UNDELIV'
  //         recipient['statusMessage'] = 'Queued for delivery'
  //       } else if (recipient['Status'] == 'ACCEPTD') {
  //         recipient['Status'] = 'UNDELIV'
  //         recipient['statusMessage'] = 'Message is in accepted state'
  //       } else {
  //         recipient['Status'] = 'UNDELIV'
  //         recipient['statusMessage'] = 'Ingen status'
  //       }
  //       if (recipient['event'] == 'processed') {
  //         recipient['statusMessage'] = 'Sender..'
  //         recipient['statusColor'] = 'warning'
  //         recipient['Status'] = 'ENROUTE'
  //       } else if (recipient['event'] == 'dropped') {
  //         recipient['statusMessage'] = 'Feil ved sending...'
  //         recipient['statusColor'] = 'danger'
  //         recipient['Status'] = 'UNDELIV'
  //       } else if (recipient['event'] == 'deferred') {
  //         recipient['statusMessage'] = 'Feil ved sending...'
  //         recipient['statusColor'] = 'danger'
  //         recipient['Status'] = 'UNDELIV'
  //       } else if (recipient['event'] == 'bounce') {
  //         recipient['statusMessage'] = 'Feil ved sending...'
  //         recipient['statusColor'] = 'danger'
  //         recipient['Status'] = 'UNDELIV'
  //       } else if (recipient['event'] == 'delivered') {
  //         recipient['statusMessage'] = 'Levert'
  //         recipient['statusColor'] = 'success'
  //         recipient['Status'] = 'DLVRD'
  //       } else if (recipient['event'] == 'open') {
  //         recipient['statusMessage'] = 'Åpnet'
  //         recipient['statusColor'] = 'success'
  //         recipient['Status'] = 'OPEN'
  //       }
  //       if (recipient['Status']) {
  //         // console.log(this.recipientCount)
  //         // console.log(recipient['status'])
  //         // console.log(recipient['Status'])
  //         this.recipientCount[recipient['Status']].push(recipient);
  //       }
  //     })
  //     return recipients.sort((a, b) => {
  //       if (a['firstname'] > b['firstname'])
  //         return -1;
  //       if (a['firstname'] < b['firstname'])
  //         return 1;
  //       return 0;
  //    });      
  //   }));
  //   this.recipients.subscribe(recipients => {
  //     this.recipientsTable.data = recipients;
  //   })
  // }

  countRecipientStatus() {
    this.recipientCount = {
      sending: 0,
      success: 0,
      error: 0,
      rejected: 0,
      opened: 0
    }
    this.recipients.data.forEach(recipient => {
      this.addStatus(recipient)
    })
  }

  first: boolean = false
  recipientsSub: Subscription
  getRecipients() {
    this.filterStatus = '';
    this.first = true
    this.recipients.data = []
    if (this.recipientsSub) this.recipientsSub.unsubscribe()
    this.recipientsSub = this.messagedb.getMessage(this.message.id).collection('recipients').stateChanges().subscribe(recipientsColl => {
      if (this.first) {
        this.recipients.data = recipientsColl.map(recipientSnap => recipientSnap.payload.doc.data())
        this.countRecipientStatus()
        this.first = false
      } else {
        recipientsColl.forEach(recipientSnap => {
          const recipient: messageRecipientDocument = recipientSnap.payload.doc.data()
          const i = this.recipients.data.findIndex(e => e.recipient.id == recipient.recipient.id)
          if (recipientSnap.type == 'added' && recipientSnap.payload.oldIndex == -1) {
            if (i == -1) {
              this.recipients.data.push(recipient)
              this.addStatus(recipient)
            }
          }
          if (recipientSnap.type == 'modified') {
            if (i > -1) {
              this.addStatus(this.recipients.data[i], true)
              this.recipients.data[i] = recipient
              this.addStatus(recipient)
            } else {
              this.recipients.data.push(recipient)
            }
          }
          if (recipientSnap.type == 'removed') {
            this.addStatus(this.recipients.data[i], true)
            if (i > -1) this.recipients.data.splice(i, 1)
          }
        })
      }
      this.recipients.paginator = this.paginator
      this.recipients.sort = this.sort
    })
  }

  
  addStatus(recipient: messageRecipientDocument, decrease?: boolean) {
    if (this.message.messageType == 'email') {
      const status = this.emailMessageStatus.find(s => s.apiStatus == recipient.apiStatus)
      if (status) recipient.statusData = status
    } else if (this.message.messageType == 'sms') {
      const status = this.smsMessageStatus.find(s => s.apiStatus == recipient.apiStatus)
      if (status) recipient.statusData = status
    }
    if (recipient.statusData) {
      if (recipient.statusData.status == 'queued' || recipient.statusData.status == 'sending') {
        if (!decrease) {
          this.recipientCount.sending++
        } else {
          if (this.recipientCount.sending > 0) this.recipientCount.sending--
        }
      } else if (recipient.statusData.status == 'error') {
        if (!decrease) {
          this.recipientCount.error++
        } else {
          if (this.recipientCount.error > 0) this.recipientCount.error--
        }
      } else if (recipient.statusData.status == 'rejected') {
        if (!decrease) {
          this.recipientCount.rejected++
        } else {
          if (this.recipientCount.rejected > 0) this.recipientCount.rejected--
        }
      } else if (recipient.statusData.status == 'success') {
        if (!decrease) {
          this.recipientCount.success++
        } else {
          if (this.recipientCount.success > 0) this.recipientCount.success--
        }
      } else if (recipient.statusData.status == 'opened') {
        if (!decrease) {
          this.recipientCount.opened++
        } else {
          if (this.recipientCount.opened > 0) this.recipientCount.opened--
        }
      }
    }
  }

  generateDuDere(user: GuestElement, capital: boolean) {
    if (user.groupCount > 1) {
      if (capital) {
        return 'Dere'
      } else {
        return 'dere'
      }
    } else {
      if (capital) {
        return 'Du'
      } else {
        return 'du'
      }
    }
  }

  generateDegDere(user: GuestElement, capital: boolean) {
    if (user.groupCount > 1) {
      if (capital) {
        return 'Dere'
      } else {
        return 'dere'
      }
    } else {
      if (capital) {
        return 'Deg'
      } else {
        return 'deg'
      }
    }
  }

  generateInvitationLink(user) {
    return 'hannaogperchristian.no/inv/' + user.invitationId
  }

  messageReplace(message:string, user) {
    if (!user.groupName || user.groupName == '') {
      if (user.nickName) {
        user.groupName = user.nickName
      } else {
        user.groupName = user.firstname
      }
    }
    if (message.includes('#FNAVN#')) message = message.replace(/#FNAVN#/g, user.firstname);
    if (message.includes('#ENAVN#')) message = message.replace(/#ENAVN#/g, user.lastname ? user.lastname : '');
    if (message.includes('#KNAVN#')) message = message.replace(/#KNAVN#/g, user.nickName ? user.nickName : user.firstname);
    if (message.includes('#NAVN#')) message = message.replace(/#NAVN#/g, user.firstname + ' ' + user.lastname);
    if (message.includes('#INVID#')) message = message.replace(/#INVID#/g, user.invitationId);
    if (message.includes('#INVLINK#')) message = message.replace(/#INVLINK#/g, this.generateInvitationLink(user));
    if (message.includes('#GRUPPENAVN#')) message = message.replace(/#GRUPPENAVN#/g, user.groupName);
    if (message.includes('#DUDERE#')) message = message.replace(/#DUDERE#/g, this.generateDuDere(user, false));
    if (message.includes('#DEGDERE#')) message = message.replace(/#DEGDERE#/g, this.generateDegDere(user, false));
    return message;
  }

  checkMessage(): boolean {
    if (!this.message.recipients || this.message.recipients.length == 0) {
      this.messages.showMessage('Meldingen mangler mottakere...')
      return false
    } else if (this.message.messageType == 'sms') {
      if (!this.message.message.smsMessage || this.message.message.smsMessage.length == 0) {
        this.messages.showMessage('Meldingen mangler innhold...')
        return false
      } else if (this.SMScalc.price > this.smsBalance.saldo) {
        this.messages.showMessage('Det er ikke nokpenger på SMS-kontoen til å sende SMSene...')
        return false
      }
    } else if (this.message.messageType == 'email') {
      if (!this.message.message.subject || this.message.message.subject.length == 0) {
        this.messages.showMessage('Meldingen mangler emne...')
        return false
      }
      if (!this.message.message.html || this.message.message.html.length == 0) {
        this.messages.showMessage('Meldingen mangler innhold...')
        return false
      }
    }
    return true
  }

  sendMessage() {
    let recipients = []
    let noInvitationID = false
    this.message.recipients = []
    this.selectedGuests.forEach((user: GuestElement) => {
      if (!user.invitationId) noInvitationID = true
      let groupName = ''
      if (user.groupName) {
        groupName = user.groupName
      } else if (user.nickName) {
        groupName = user.nickName
      } else {
        groupName = user.firstname
      }
      if (user.phone) {
        this.message.recipients.push({
          id: user.id,
          fullname: user.firstname + ' ' + user.lastname,
          firstname: user.firstname,
          lastname: user.lastname,
          countryCode: '+47',
          phone: (user.phone as any).toString(),
          groupName: groupName,
          invlink: this.generateInvitationLink(user),
          dudere: this.generateDuDere(user, false),
          degdere: this.generateDegDere(user, false),
        })
      }
    })
    this.message.message.mergeFields = this.compileMergeTags()
    console.log(this.message.message.mergeFields)
    if (noInvitationID) {
      alert('Feil med invID')
      return false
    }
    if (this.checkMessage()) {
      this.working = true
      // this.modalConfig.ignoreBackdropClick = true
      this.message.status = 'queued'
      this.message.date = new Date()
      console.log(this.message)
      this.messagedb.getMessage(this.message.id).set(this.message, {merge: true}).then(() => {
        this.working = false
        // this.modalConfig.ignoreBackdropClick = false
        this.message.status = 'processing'
        // this.newMessageEmitter.emit(this.msg)
          this.newMessageBool = false;
        this.getRecipients();
        this.messages.showSnackbar('Meldingen er sendt!', 'success')
      }).catch(err => {
        this.working = false
        // this.modalConfig.ignoreBackdropClick = false
        this.messages.showSnackbar('Det skjedde noe galt ved sending av meldingen...', 'error')
      })
    }    
    // if (noInvitationID) {
    //   alert('Feil med invID')
    // } else if (this.SMScalc['letterCount'] > 0) {
    //   if (+this.clockworkBalance['balance'] > (this.SMScalc['SMSCount'] * 0.06 * this.selectedGuestsCount)) {
    //     this.selectedGuests.forEach((user: GuestElement) => {
    //       if (user.phone) {
    //         console.log(this.messageReplace(this.message.message.smsMessage, user))
    //         recipients.push({
    //           smsMessage: this.messageReplace(this.message.message.smsMessage, user),
    //           id: user.id,
    //           name: user.firstname + ' ' + user.lastname,
    //           landCode: 47,
    //           phone: user.phone,
    //           cwPhone: '47' + user.phone
    //         })
    //       }
    //     });
    //     this.message.recipients = recipients
    //     console.log(this.message);
    //     // Følgende legger meldingen i kø på serveren!
    //     this.messagedb.newMessage(this.message).then(success => {
    //       this.newMessageBool = false;
    //       this.getRecipients();
    //     })
    //   } else {
    //     alert('Det er for lite penger på konto...')
    //   }
    // } else {
    //   alert('Du har skrevet for lang SMS...')
    // }
  }


  compileMergeTags(): Array<{key: string, value: string}> {
    const mergeFields: Array<{key: string, value: string}> = []
    this.mergeFields.forEach(field => {
      if (this.message.messageType == 'email') {
        if (this.message.message.html && this.message.message.html.includes(field.value)) {
          mergeFields.push({key: field.value, value: field.key})
        }
      } else if (this.message.messageType == 'sms') {
        console.log(field, this.message.message.smsMessage)
        if (this.message.message.smsMessage && this.message.message.smsMessage.includes(field.value)) {
          mergeFields.push({key: field.value, value: field.key})
        }
      }
    })
    return mergeFields
  }  

  leaveTextField() {
    var el = this.messageEditor.nativeElement
    // console.log(el)
    this.caretPos = this.getCaretPosition(el)
    // console.log(this.caretPos);
  }

  getCaretPosition(element) {
    var caretOffset = 0;
    var range = window.getSelection().getRangeAt(0)
    var preCaretRange = range.cloneRange()
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    this.preCaretRange = preCaretRange
    this.caretElement = element
    this.range = range
    caretOffset = preCaretRange.toString().length;
    caretOffset = element.selectionStart
    this.caretPosEnd = element.selectionEnd
    return caretOffset;
}

insertVariable(field: string) {
  this.message.message.smsMessage
  this.message.message.smsMessage = this.message.message.smsMessage.slice(0, this.caretPos) + field + this.message.message.smsMessage.slice(this.caretPosEnd);
  setTimeout(() => {
    this.messageEditor.nativeElement.focus()
    this.messageEditor.nativeElement.setSelectionRange(this.caretPos + field.length, this.caretPos + field.length)
  }, 100);
}

  ngOnDestroy() {
    if (this.subscriptionArray.length > 0) {
      this.subscriptionArray.forEach(sub => {
        sub.unsubscribe();
      })
    }
  }

  openModal(msg) {
    console.log(msg)
    this.messageDetailModal.show();
    this.message = msg
    this.newMessageBool = false;
    this.working = false;
    if(!msg.signature) this.message['signature'] = msg.sender;
    this.getRecipients();
  }
}
