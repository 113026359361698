import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, ActivatedRoute } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ButtonsModule, BsDropdownModule, BsDatepickerModule, ModalModule, AccordionModule, TabsModule } from 'ngx-bootstrap';


//Components
import { messageDetail } from './messageDetail.component';

import { NgxEditorModule } from 'ngx-editor';
import { SearchPipe_user, SearchPipe_Status } from '../pipes/search-pipe';
import { MatTableModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatSortModule, MatOptionModule, MatSelectModule, MatMenuModule, MatPaginatorModule } from '@angular/material';


export const routes = [
  { path: '', component: messageDetail, pathMatch: 'full' },
];

@NgModule({
  declarations: [
    // Components / Directives/ Pipes
    messageDetail,
    // Components

    // Pipes
    SearchPipe_user,
    SearchPipe_Status
  ],
  exports: [messageDetail],
  imports: [
    // AccordionModule.forRoot(),
    TabsModule.forRoot(),
    CommonModule,
    FormsModule,
    ModalModule,
    NgxEditorModule,
    MatTableModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatMenuModule,
    MatPaginatorModule,
    BsDropdownModule.forRoot()
  ]
})
export class messageDetailModule {
  messageDetail
  // static routes = routes;
}
