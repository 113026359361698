import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';


declare let $: any

@Component({
  selector: 'app-front-page',
  templateUrl: './front-page.component.html',
  styleUrls: ['./front-page.component.scss']
})
export class FrontPageComponent implements OnInit {

	frontPageSections
	frontPageMenu: Array<any> = []
	groupId: string;

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.afs.collection('pages').doc('VVe8r8fF0Ii1EfjBCKU0').collection('sections', ref => ref.orderBy('orderID')).valueChanges().subscribe(frontPage => {
		this.frontPageSections = frontPage.filter(f => f.active)
		this.frontPageMenu = [];
		frontPage.forEach(menu => {
			if (menu.menuTitle && menu.active) {
				this.frontPageMenu.push(menu)
			}
		})
		setTimeout(() => {
			this.navigationSection()
			this.removeTarget()
			route.params.subscribe(params => {
				var section = params['section'];
				if (section) {
					this.scrollToElement(section)
				}
			})
			route.fragment.subscribe(fragment => {
				if (fragment && fragment.length > 2) {
					this.scrollToElement(fragment)
				}
			})
		}, 100);
    })
  }

  removeTarget() {
	console.log('removingTarget');
	// $('.container').find('a').each(element => {
	// 	console.log($('.container').find('a')[element])
	// 	// element.removeAttr('ta')
	// });
  }

  login() {
	  console.log('Logger inn!')
	  this.router.navigate(['/admin'])
  }

  goToInvite() {
	this.router.navigate(['/invitasjon/' + this.groupId])
  }

  goToWishlist(type) {
	  if (type == 'cg') {
		window.open('https://bryllupsliste.cg.no/weddinglist/view/index/id/45gW9gWMQvpdid2svqGFrSSJZ4OCotb2/', '_blank')
	  } else if (type == 'xxl') {
		this.router.navigate(['/onskeliste'])
	  }
  }

  scrollToElement(target) {
    $([document.documentElement, document.body]).animate({
      scrollTop: $("#" + target).offset().top - 100
	}, 500);
	if (target == 'home') {
		$('#qbootstrap-header').removeClass('navbar-fixed-top');
	}
	$('.js-qbootstrap-nav-toggle').removeClass('active');
	$('#navbar').addClass('collapse');
  }

  menuClick() {
    // if ( $('#navbar').is(':visible') ) {
    //   $('#navbar').removeClass('active');	
    //   $('#navbar').addClass('collapse');
    // } else {
    //   $('#navbar').addClass('active');
    //   $('#navbar').removeClass('collapse');
    // }    
  }



	// var navActive = function(section) {

		// var $el = $('#navbar > ul');
		// $el.find('li').removeClass('active');
	// 	$el.each(function(){
	// 		$(this).find('a[data-nav-section="'+section+'"]').closest('li').addClass('active');
	// 	});

	// };
	navigationSection() {

		// var $section = $('div[data-section]');
		
		$('div[data-section]').waypoint(function(direction) {      
			if (direction === 'down') {
				console.log($(this.element).data('section'))
				var section = $(this.element).data('section');
				var $el = $('#navbar > ul');
				$el.find('li').removeClass('active');
				$el.each(function(){
					$(this).find('a[data-nav-section="'+section+'"]').closest('li').addClass('active');
				});
			}
		}, {
			offset: '300px'
		});

		$('div[data-section]').waypoint(function(direction) {
			if (direction === 'up') {
				console.log($(this.element).data('section'))
				// this.navActive($(this.element).data('section'));
				var section = $(this.element).data('section');
				var $el = $('#navbar > ul');
				$el.find('li').removeClass('active');
				$el.each(function(){
					$(this).find('a[data-nav-section="'+section+'"]').closest('li').addClass('active');
				});
			}
		}, {
			offset: function() { return -$(this.element).height() + 300; }
		});

	}

  

  ngOnInit() {
	if (localStorage.getItem('invitationId')) {
		this.groupId = localStorage.getItem('invitationId');
		console.log(this.groupId)
	}
    var lastScrollTop = 0;
		$('body').on('click', '.js-qbootstrap-nav-toggle', function(event){
			if ( $('#navbar').is(':visible') ) {
				$(this).removeClass('active');
				$('#navbar').addClass('collapse');
			} else {
				$(this).addClass('active');	
				$('#navbar').removeClass('collapse');
			}
			event.preventDefault();
		});
	$(window).resize(() => {
		// console.log($(window).width());
		if ($(window).width() >= 1200) {
			$('#vielse').removeClass('col-md-push-3')
			$('#middag').removeClass('col-md-push-3')
		} else {
			$('#vielse').addClass('col-md-push-3')
			$('#middag').addClass('col-md-push-3')
		}
	});
    
		// $('a:not([class="external"])').click(function(event){
		// 	var section = $(this).data('nav-section'),
		// 		navbar = $('#navbar');
		//     // $('html, body').animate({
		//     //     scrollTop: $('[data-section="' + section + '"]').offset().top
		//     // }, 500);

		//     if ( navbar.is(':visible')) {
		//     	navbar.removeClass('in');
		//     	navbar.attr('aria-expanded', 'false');
		//     	$('.js-qbootstrap-nav-toggle').removeClass('active');
		//     }

		//     event.preventDefault();
		//     return false;
		// });    

		$(window).scroll(function(event){

		   	var header = $('#qbootstrap-header'),
				scrlTop = $(this).scrollTop();
			if ( scrlTop > 85 && scrlTop <= 2000 ) {
				header.addClass('navbar-fixed-top');
			} else if ( scrlTop > 0 && scrlTop <= 500) {
				if ( header.hasClass('navbar-fixed-top') ) {
					header.removeClass('navbar-fixed-top');
				}
			}
		});
  }

}
