import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { MatSlideToggleChange, MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { AngularFirestore } from '@angular/fire/firestore';
import {MatSort} from '@angular/material/sort';
import * as firebase from 'firebase/app';
import { map } from 'rxjs/operators';


export interface PagesElement {
  index: number;
  id: string;
  name: string;
  phone: number;
  role: number;
  groupId: string;
}

export interface sectionElement {
  id: string,
  textfield_1: string,
  eventTitle_1: string,
  eventImage_1: string,
  date_1: string,
  time_1: string,
  image_1: string,
  role_1: string,
  textfield_2: string,
  avlystText?: string,
  eventTitle_2: string,
  eventImage_2: string,
  date_2: string,
  time_2: string,
  image_2: string,
  role_2: string,
  symbol: string,
  inviteText_single: string,
  inviteText_both: string,
  type: string,
  menuTitle: string,
  title: string,
}

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  sections = new MatTableDataSource([]);
  columnsToDisplay = ['button', 'select', 'title', 'type', 'order', 'symbol', 'active',  'delete'];
  selection = new SelectionModel<PagesElement>(true, []);
  @ViewChild('sectionDetailModal',{static: true}) public sectionDetailModalChild;
  @ViewChild(MatSort, {static: true}) sort: MatSort;


  currentPageId: string;
  currentSection: sectionElement = {
    id: '',
    textfield_1: '',
    eventTitle_1: '',
    eventImage_1: '',
    role_1: '',
    date_1: '',
    time_1: '',
    image_1: '',
    textfield_2: '',
    eventTitle_2: '',
    eventImage_2: '',
    role_2: '',
    date_2: '',
    time_2: '',
    image_2: '',
    symbol: '',
    inviteText_single: '',
    inviteText_both: '',
    type: '',
    menuTitle: '',
    title: '',
  }

  quillConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      [{ 'header': [1, 2, 3, false] }],
      [{ 'color': [] }], // dropdown with defaults from theme
      [{ 'align': [] }],
      ['clean'], // remove formatting button
      ['link']
    ]
  };

  editorConfig = {
    'height': 'auto',
    'minHeight': '100',
    'width': 'auto',
    "toolbar": [
      ["bold", "italic", "underline"],
      // ["fontName", "fontSize", "color"],
      ["justifyLeft", "justifyCenter", "justifyRight"],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
    ]
  }

  constructor(
    public afAuth: AngularFireAuth, 
    private afs: AngularFirestore,
    private router: Router) {
  
  }

  logOut() {
    this.afAuth.auth.signOut().then(() => {
      console.log('sign out')
      this.router.navigate(['/frontPage'])
    })
  }


  getInvite() {
    this.afs.collection('pages').doc('HkCO4357d7SyZLihl3Pv').valueChanges().subscribe((section: sectionElement) => {
      // this.guests = new MatTableDataSource(guests);
      // guests.forEach(guest => {
      //   guest['orderID'] = guest['orderID'].toString()
      // })
      this.currentSection = section
      this.sections.sort = this.sort;
      this.selection.clear();
    })
  }

  getAvlyst() {
    this.afs.collection('pages').doc('t2zg4Ja3ZP3t6EHxge1J').valueChanges().subscribe((section: sectionElement) => {
      // this.guests = new MatTableDataSource(guests);
      // guests.forEach(guest => {
      //   guest['orderID'] = guest['orderID'].toString()
      // })
      this.currentSection = section
      this.sections.sort = this.sort;
      this.selection.clear();
    })
  }

  getWishlist() {
    this.afs.collection('pages').doc('IA8ZLWOBJfU8n0SCPzBg').valueChanges().subscribe((section: sectionElement) => {
      // this.guests = new MatTableDataSource(guests);
      // guests.forEach(guest => {
      //   guest['orderID'] = guest['orderID'].toString()
      // })
      this.currentSection = section
      this.sections.sort = this.sort;
      this.selection.clear();
    })
  }

  getSections(pageId) {
      this.afs.collection('pages').doc(pageId).collection('sections').valueChanges().pipe(map(sections => {
        sections.sort((a, b) => {
          if (a['orderID'] < b['orderID'])
            return -1;
          if (a['orderID'] > b['orderID'])
            return 1;
          return 0;
        });
        return sections
      })).subscribe(sections => {
        this.sections.data = sections
        this.sections.sort = this.sort;
        this.selection.clear();
      })
  }

  changeGuest(guest) {
    guest.orderID = +guest.orderID
    this.afs.collection('guests').doc(guest.id).update(guest)
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.sections.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.sections.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PagesElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index}`;
  }  

  applyFilter(filterValue: string) {
    this.sections.filter = filterValue.trim().toLowerCase();
  }

  applyFilterGroup(group) {
    this.sections.filterPredicate = (data, filter: string): boolean => {
      return data.name.toLowerCase().includes(filter) || data.symbol.toLowerCase().includes(filter) || data.position.toString().includes(filter);
  };
  }

  hideModal() {

  }

  closeModal() {
    this.sectionDetailModalChild.hide();
  }

  changeActive(section, event: MatSlideToggleChange) {
    section.active = event.checked
    this.afs.collection('pages').doc(this.currentPageId).collection('sections').doc(section['id']).update({active: section.active})
  }

  deleteSection(section) {
    var melding = confirm('Er du helt sikker på at du vil slette denne sidedelen?');
    if (melding == true) {
      this.afs.collection('pages').doc(this.currentPageId).collection('sections').doc(section['id']).delete();
    }
  }

  updateOrder(section) {
    this.afs.collection('pages').doc(this.currentPageId).collection('sections').doc(section['id']).update({orderID: section.orderID});
  }

  updateType(section) {
    this.afs.collection('pages').doc(this.currentPageId).collection('sections').doc(section['id']).update({type: section.type});
  }

  updateSymbol(section) {
    this.afs.collection('pages').doc(this.currentPageId).collection('sections').doc(section['id']).update({symbol: section.symbol});
  }

  saveSection() {
    if (this.currentSection['type'] =='wishlist') {
      this.afs.collection('pages').doc('IA8ZLWOBJfU8n0SCPzBg').set(this.currentSection, {merge: true});
    } else if (this.currentSection['type'] =='invitePage') {
      this.afs.collection('pages').doc('HkCO4357d7SyZLihl3Pv').set(this.currentSection, {merge: true});
    } else if (this.currentSection['type'] =='avlyst') {
      this.afs.collection('pages').doc('t2zg4Ja3ZP3t6EHxge1J').set(this.currentSection, {merge: true});
    } else {
      this.afs.collection('pages').doc(this.currentPageId).collection('sections').doc(this.currentSection['id']).set(this.currentSection, {merge: true});
    }
    this.sectionDetailModalChild.hide();
  }

  openInvite() {
    this.getInvite()
    this.sectionDetailModalChild.show();
  }

  openAvlyst() {
    this.getAvlyst()
    this.sectionDetailModalChild.show();
  }

  openWishlist() {
    this.getWishlist()
    this.sectionDetailModalChild.show();
  }

  openSection(section) {
    this.currentSection = section;
    this.sectionDetailModalChild.show();
  }

  newSection() {
    this.currentSection = {
      id: firebase.firestore().collection('guests').doc().id,
      textfield_1: '',
      eventTitle_1: '',
      eventImage_1: '',
      role_1: '',
      date_1: '',
      time_1: '',
      image_1: '',
      textfield_2: '',
      eventTitle_2: '',
      eventImage_2: '',
      role_2: '',
      date_2: '',
      time_2: '',
      image_2: '',
      symbol: '',
      inviteText_single: '',
      inviteText_both: '',
      type: '',
      menuTitle: '',
      title: '',
    }
    this.sectionDetailModalChild.show();
  }

  ngOnInit() {
    this.currentPageId = 'VVe8r8fF0Ii1EfjBCKU0'
    this.getSections(this.currentPageId);
  }

}
