// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCOUZzfdz2mzekobFPDw0m2LDaXRyT4yN0",
    authDomain: "wedding-a452e.firebaseapp.com",
    databaseURL: "https://wedding-a452e.firebaseio.com",
    projectId: "wedding-a452e",
    storageBucket: "wedding-a452e.appspot.com",
    messagingSenderId: "686969553452",
    appId: "1:686969553452:web:4f4311c4bd6c09d46cc789"
  },
  emulator: true,
  emulator_functions: false
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
