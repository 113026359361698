import { messageDatabase } from '../../Database';
import { Component, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { RouterModule, ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase/app';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';

declare let jQuery: any;

@Component({
  selector: 'messages',
  templateUrl: './messages.template.html',
  styleUrls: ['./messages.style.scss'],
  encapsulation: ViewEncapsulation.None
})
export class messages {
  @ViewChild('messagesDetail',{static: true}) public messagesDetailChild;

  messages: Observable<any>;
  messagesTable = new MatTableDataSource([]);
  viewMessages: string = 'user';
  columnsToDisplay = ['button', 'message', 'status', 'date', 'delete'];

  constructor(
    private afs: AngularFirestore, 
    private route: ActivatedRoute,
    private messagedb: messageDatabase) {
      this.messages = messagedb.getAllMessages().pipe(
        map((messages: any[]) => messages.map(message => {
          if (message.sentMessage == true) {
            message['statusText'] = 'Meldingen blir behandlet..';
            message['statusMessage'] = 'Meldingen er på vei ut fra Crew sin server!';
          }
          if (message.status == 'error') {
            message.statusText = 'Sending feilet..';
            if (message.errorMsg) {
              message.statusMessage = message.errorMsg;
            } else {
              message.statusMessage = 'Noe galt skjedde... Kontakt administrator!'
            }
          } else if (message.status == 'success') {
            message.statusText = 'Sendt!';
            message.statusMessage = 'Meldingen er sendt';
          }
          message.date = this.convertFirestoreTimestamp(message.date)
          return message
        }))
      //   map(msg => {msg.map((m: any) => {
      //   m.date = this.convertFirestoreTimestamp(m.date)
      // })}));
      )
      this.sortMessages()
      this.messages.subscribe(messages => {
        this.messagesTable.data = messages
      })
  }

  applyFilter(filterValue: string) {
    this.messagesTable.filter = filterValue.trim().toLowerCase();
  }

  sortMessages() {
    this.messages = this.messages.pipe(map(messages => {
      messages.forEach(message => {
        if (message['sentMessage'] == true) {
          message['statusText'] = 'Meldingen blir behandlet..';
          message['statusMessage'] = 'Meldingen er på vei ut fra Crew sin server!';
        }
        message['smsMessage'] = message['message'].smsMessage
        if (message.messageType == 'Epost') {
          const messageShort: string = message.message.subject
          let dots = '...'
          if (messageShort.length < 50) dots = ''
          message.message['messageShort'] = messageShort.slice(0,50) + dots
        } else if (message.messageType == 'SMS' || message.messageType == 'sms') {
          const messageShort: string = message.message.smsMessage
          let dots = '...'
          if (messageShort.length < 50) dots = ''
          message.message['messageShort'] = messageShort.slice(0,50) + dots
        }
        if (message['status'] == false) {
          message['statusText'] = 'Sending feilet..';
          if (message['errorMsg']) {
            message['statusMessage'] = message['errorMsg'];
          } else {
            message['statusMessage'] = 'Noe galt skjedde... Kontakt administrator!'
          }
        } else if (message['status'] == true) {
          message['statusText'] = 'Sendt!';
          message['statusMessage'] = 'Meldingen er sendt';
        }        
      })
      return messages.sort((a, b) => {
        if (a['date'] > b['date'])
          return -1;
        if (a['date'] < b['date'])
          return 1;
        return 0;
    });      
  }))
  }

  newMessage() {
    this.messagesDetailChild.newMessage('Epost');
  }

  openMessage(msg) {
    this.messagesDetailChild.openModal(msg)
  }

  ngAfterViewInit() {
    
  }

  deleteMessage(message) {
    var melding = confirm('Er du sikker på at du vil slette meldingen?');
    if (melding == true) {
      this.messagedb.getMessage(message.id).delete();
    }    
  }  
  
  ngOnInit(): void {

  }


  convertFirestoreTimestamp(firebaseTimestamp: any): Date {
    if (firebaseTimestamp && firebaseTimestamp instanceof firebase.firestore.Timestamp && firebaseTimestamp['seconds']) {
        let date_end = firebaseTimestamp
        return date_end.toDate()
    } else if (firebaseTimestamp && firebaseTimestamp['seconds']) {
        // return moment.unix(firebaseTimestamp['seconds']).toDate()
        return new Date(firebaseTimestamp)
    } else {
        return new Date(firebaseTimestamp)
    }
}
}
