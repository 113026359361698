import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesComponent } from './pages/pages.component';
import { RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { MatMenuModule, MatIconModule, MatTableModule, MatFormFieldModule, MatInputModule, MatSortModule, MatButtonModule, MatCheckboxModule, MatChipsModule, MatSelectModule, MatOptionModule, MatPaginatorModule, MatSlideToggleModule } from '@angular/material';
import { guestListComponent } from './guest-list/guest-list.component';
import { messagesModule } from './messages/messages.module';
import { messageDetailModule } from './messages/messageDetail/messageDetail.module';
// import { messageDetailModule } from './messages/messageDetail/messageDetail.module';
import { ButtonsModule, BsDropdownModule, BsDatepickerModule, ModalModule, AccordionModule, TabsModule } from 'ngx-bootstrap';
import { FormsModule } from '@angular/forms';
import { NgxEditorModule } from 'ngx-editor';
import { HttpClientModule } from '@angular/common/http';
import { AdminNavModule } from './admin-nav/admin-nav.module';
import { wishlistComponent } from './wishlist/wishlist.component';
import { QuillModule } from 'ngx-quill'


const routes: Routes = [
  {path: '', redirectTo: 'gjesteliste', pathMatch: 'full' },
  {path: 'gjesteliste', component: guestListComponent},
  {path: 'sider', component: PagesComponent},
  {path: 'onskeliste', component: wishlistComponent},
  {path: 'meldinger', loadChildren: './messages/messages.module#messagesModule'},
]

@NgModule({
  declarations: [PagesComponent, guestListComponent, wishlistComponent],
  imports: [
    messagesModule,
    CommonModule,
    RouterModule.forChild(routes),
    AngularFireAuthGuardModule,
    MatMenuModule,
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatSelectModule,
    MatOptionModule,
    MatPaginatorModule,
    messageDetailModule,
    ModalModule,
    BsDropdownModule,
    FormsModule,
    MatSlideToggleModule,
    NgxEditorModule,
    HttpClientModule,
    AdminNavModule,
    QuillModule.forRoot()
  ], providers: [AngularFireAuthGuard]
})
export class AdminModule { }
