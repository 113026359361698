import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrontPageComponent } from './front-page.component';
import { RouterModule, Routes } from '@angular/router';
import { WishListFrontPageComponent } from './wish-list-front-page/wish-list-front-page.component';
import { MatCardModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { FormsModule } from '@angular/forms';


const routes: Routes = [
  {path: '', component: FrontPageComponent},
  {path: 'forside/:section', component: FrontPageComponent},
  {path: 'forside', redirectTo: '', pathMatch: 'full' },
  {path: 'onskeliste', component: WishListFrontPageComponent}
]

@NgModule({
  declarations: [
    FrontPageComponent,
    WishListFrontPageComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
  ]
})
export class FrontPageModule { }
