import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { GuestElement } from '../../interfaces/interfaces';

declare let $: any

export interface inviteElement {
  inviteText: string,
  inviteText_both: string,
  inviteText_single: string,
  title: string,
  type: string
}

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class inviteComponent implements OnInit {

  nameString = '';
  guests: Array<GuestElement> = []
  saved = false;
  note: string = '';
  pageData: inviteElement = {
    inviteText: '',
    inviteText_both: '',
    inviteText_single: '',
    title: '',
    type: ''
  }
  loading = true;
  loadingText: string = 'Laster invitasjonen din...';
  inviteType
  answered: boolean = false;
  accomodation: boolean = false

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute
  ) {
    this.afs.collection('pages').doc('HkCO4357d7SyZLihl3Pv').get().subscribe(snap => {
    // this.afs.collection('pages').doc('HkCO4357d7SyZLihl3Pv').valueChanges().subscribe((data: inviteElement) => {
      this.pageData = snap.data() as inviteElement
    })
    route.params.subscribe(params => {
      if (params.id) {
        this.afs.collection('guests', ref => ref.where('invitationId', '==', params.id)).get().subscribe(guestsColl => {
          guestsColl.forEach(guestSnap => {
            let guestData: GuestElement = guestSnap.data()
            if (guestData.visitCount) {
              guestData.visitCount++
            } else {
              guestData.visitCount = 1
            }
            guestSnap.ref.update({lastVisited: new Date().getTime(), visitCount: guestData.visitCount})
          })
        });
        this.afs.collection('guests', ref => ref.where('invitationId', '==', params.id)).valueChanges().subscribe((guests: GuestElement[]) => {
          this.guests = []
          this.nameString = '';
          console.log(guests)
          if (guests.length < 1) {
            this.loadingText = 'Fant ingen invitasjon med koden ' + params.id + '!'
            localStorage.removeItem('invitationId')
          } else {
            localStorage.setItem('invitationId', params.id)
            guests = guests.sort((a, b) => {
              if (a.orderID < b.orderID)
                return -1;
              if (a.orderID > b.orderID)
                return 1;
              return 0;
            });
            this.pageData.inviteText_single = this.messageReplace(this.pageData.inviteText_single, guests[0])
            this.pageData.inviteText_both = this.messageReplace(this.pageData.inviteText_both, guests[0])
            this.inviteType = guests[0].invite
            this.answered = guests[0].answered ? guests[0].answered : false
            this.accomodation = false
            guests.forEach(guest => {
              if (guest.accomodation) this.accomodation = true
              let guestData = guest
              console.log(this.inviteType)
              if (!guestData.attending) guestData.attending = {}
              if (!guestData.attending.friday) guestData.attending.friday = false
              if (!guestData.attending.saturday) guestData.attending.saturday = false
              if (guestData.note) this.note = guestData.note;
              if (guestData.groupName) {
                this.nameString = guestData.groupName
              } else if (guestData.nickName) {
                this.nameString = guestData.nickName
              } else {
                this.nameString = guestData.firstname
              }
              this.guests.push(guestData)
              if (guestData.visitCount) {
                guestData.visitCount++
              } else {
                guestData.visitCount = 1
              }
              
            })
          }
          if (guests.length > 0) this.loading = false
        });
      }
    });
  }

  generateDuDere(user, capital: boolean) {
    console.log(user);
    if (user.groupCount > 1) {
      if (capital) {
        return 'Dere'
      } else {
        return 'dere'
      }
    } else {
      if (capital) {
        return 'Du'
      } else {
        return 'du'
      }
    }
  }

  generateInvitationLink(user) {
    return 'hannaogperchristian.no/inv/' + user.invitationId
  }

  messageReplace(message:string, user) {
    if (!user.groupName || user.groupName == '') {
      if (user.nickName) {
        user.groupName = user.nickName
      } else {
        user.groupName = user.firstname
      }
    }
    if (message.includes('#FNAVN#')) message = message.replace(/#FNAVN#/g, user.firstname);
    if (message.includes('#ENAVN#')) message = message.replace(/#ENAVN#/g, user.lastname);
    if (message.includes('#KNAVN#')) message = message.replace(/#KNAVN#/g, user.nickName);
    if (message.includes('#NAVN#')) message = message.replace(/#NAVN#/g, user.firstname + ' ' + user.lastname);
    if (message.includes('#INVID#')) message = message.replace(/#INVID#/g, user.invitationId);
    if (message.includes('#INVLINK#')) message = message.replace(/#INVLINK#/g, this.generateInvitationLink(user));
    if (message.includes('#GRUPPENAVN#')) message = message.replace(/#GRUPPENAVN#/g, user.groupName);
    if (message.includes('#DUDERE#')) message = message.replace(/#DUDERE#/g, this.generateDuDere(user, false));
    if (message.includes('#DEGDERE#')) message = message.replace(/#DEGDERE#/g, this.generateDegDere(user, false));
    return message;
  }

  generateDegDere(user, capital: boolean) {
    if (user.groupCount > 1) {
      if (capital) {
        return 'Dere'
      } else {
        return 'dere'
      }
    } else {
      if (capital) {
        return 'Deg'
      } else {
        return 'deg'
      }
    }
  }

  attending(event, day, i) {
    if (!this.guests[i].attending) this.guests[i].attending = {}
    this.guests[i].attending[day] = event.target.checked;
    console.log(this.guests)
  }

  toggleAccomodation(event: any) {
    this.guests.forEach(guest => {
      guest.accomodation = event.target.checked
    })
  }

  saveGuests() {
    this.guests.forEach(guest => {
      guest['answered'] = true;
      guest['note'] = this.note;
      this.afs.collection('guests').doc(guest.id).update(guest).catch(error => {
        console.log(error)
      }).then(() => {
        this.saved = true;
        this.answered = true;
      });
    });
  }

  edit() {
    this.saved = false;
  }

  scrollToElement(target) {
    $([document.documentElement, document.body]).animate({
      scrollTop: $("#" + target).offset().top
    }, 500);
  }

  ngOnInit() {
    var lastScrollTop = 0;

		$(window).scroll(function(event){

		   	var header = $('#qbootstrap-header'),
				scrlTop = $(this).scrollTop();
			if ( scrlTop > 85 && scrlTop <= 2000 ) {
				header.addClass('navbar-fixed-top qbootstrap-animated slideInDown');
			} else if ( scrlTop <= 500) {
				if ( header.hasClass('navbar-fixed-top') ) {
					header.addClass('navbar-fixed-top qbootstrap-animated slideOutUp');
					setTimeout(function(){
						header.removeClass('navbar-fixed-top qbootstrap-animated slideInDown slideOutUp');
					}, 100 );
				}
			} 
			
		});
  }

}
