import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FrontPageComponent } from './front-page/front-page.component';
import { LoginModule } from './login/login.module';
import { FrontPageModule } from './front-page/front-page.module';
import { AdminModule } from './admin/admin.module';
import { AngularFireAuthGuard, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['admin']);


const routes: Routes = [
  { path: '', loadChildren: './front-page/front-page.module#FrontPageModule'},
  { path: 'frontPage', redirectTo: '', pathMatch: 'full' },
  // { path: '', redirectTo: 'avlyst', pathMatch: 'full' },
  // { path: 'frontPage', redirectTo: 'avlyst', pathMatch: 'full' },
  { path: 'invitasjon/:id', loadChildren: './invite/invite.module#inviteModule'},
  { path: 'inv/:id', redirectTo: 'invitasjon/:id', pathMatch: 'full' },
  // { path: 'invitasjon/:id', redirectTo: 'avlyst/:id', pathMatch: 'full' },
  // { path: 'inv/:id', redirectTo: 'avlyst/:id', pathMatch: 'full' },
  // { path: 'avlyst', loadChildren: './avlyst/avlyst.module#avlystModule'},
  // { path: 'avlyst/:id', loadChildren: './avlyst/avlyst.module#avlystModule'},
  { path: 'login', loadChildren: './login/login.module#LoginModule', canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectLoggedInToItems}}, 
  { path: 'admin', loadChildren: './admin/admin.module#AdminModule', canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
