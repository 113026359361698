import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { messagesDialog, MessagesService } from './messages.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule } from '@angular/forms';



@NgModule({
    declarations: [
        messagesDialog
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        MatDialogModule,
        MatButtonModule,
        MatSnackBarModule,
        MatCheckboxModule,
    ],
    providers: [
        MessagesService
    ]
})
export class MessagesModule { }
