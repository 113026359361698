import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;

  constructor(public afAuth: AngularFireAuth, private router: Router) {
  
  }

  login() {;
    this.afAuth.auth.signInWithEmailAndPassword(this.username, this.password).then(login => {
      console.log(login.user.uid)
      this.router.navigate(['/admin']);
    });
  }

  logout() {
    this.afAuth.auth.signOut().then(signout => {
      console.log('Logget ut')
    });
  }

  ngOnInit() {
  }

}
