import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, ActivatedRoute } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ButtonsModule, BsDropdownModule, BsDatepickerModule, ModalModule, AccordionModule, TooltipModule } from 'ngx-bootstrap';

import { messages } from './messages.component';
import { messageDetailModule } from './messageDetail/messageDetail.module';
import { MatTableModule, MatButtonModule, MatSortModule, MatInputModule, MatFormFieldModule } from '@angular/material';
import { AdminNavModule } from '../admin-nav/admin-nav.module';


//Components



export const routes = [
  { path: 'meldinger', component: messages, pathMatch: 'full' },
];

@NgModule({
  declarations: [
    // Components / Directives/ Pipes
    messages,
    // Components

    // Pipes
  ],
  exports: [messages],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    ButtonsModule,
    BsDropdownModule,
    BsDatepickerModule.forRoot(),
    ModalModule,
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    messageDetailModule,
    MatTableModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    AdminNavModule
  ]
})
export class messagesModule {
  static routes = routes;
}
