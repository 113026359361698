import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-admin-nav',
  templateUrl: './admin-nav.component.html',
  styleUrls: ['./admin-nav.component.scss']
})
export class AdminNavComponent implements OnInit {

  userid
  randomText
  randomNameArray = ['PC', 'Per', 'Per Christian']
  remainingDays
  randomTextArray = [
    '#NAVN# er veldig, veldig glad i deg!😘',
    'Husk at #NAVN# elsker deg❤️',
    '#NAVN# synes at du er 🔥🔥🔥',
    '#NAVN# liker at du liker de meldingene her',
    'Pling!',
    '#NAVN# teller ned dagene igjen til 29. mai! (PS: #DAYS# dager igjen)',
  ]
  constructor(
    private router: Router,
    private afAuth: AngularFireAuth
  ) { }

  goToNav(route) {
    console.log(route);
    this.router.navigate(['/admin/' + route]);
  }

  getRemainingDays() {
    let today = new Date();
    var weddingDate = new Date(2020, 4, 29);
    var one_day=1000*60*60*24;
    this.remainingDays = Math.ceil((weddingDate.getTime()-today.getTime())/(one_day))
    console.log(this.remainingDays)
  }

  makeRandomText() {
    let randomName = this.randomNameArray[Math.floor(Math.random()*this.randomNameArray.length)];
    let randomEl = this.randomTextArray[Math.floor(Math.random()*this.randomTextArray.length)];
    this.randomText = randomEl.replace(/#NAVN#/g, randomName);
    this.randomText = this.randomText.replace(/#DAYS#/g, this.remainingDays);
  }

  ngOnInit() {
    this.afAuth.authState.subscribe(user => {
      this.userid = user.uid
    })
    this.getRemainingDays()
    this.makeRandomText()
  }

}
