import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminNavComponent } from './admin-nav.component';
import { MatMenuModule, MatIconModule, MatTableModule, MatFormFieldModule, MatInputModule, MatSortModule, MatButtonModule, MatCheckboxModule, MatChipsModule, MatSelectModule, MatOptionModule } from '@angular/material';



@NgModule({
  declarations: [
    AdminNavComponent
  ],
  exports: [AdminNavComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatSelectModule,
    MatOptionModule,
  ]
})
export class AdminNavModule {
  AdminNavComponent
}
