import { Component, Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface DialogData {
  message: string;
  okButton: string;
  cancelButton: string;
  deleteText?: string;
  checkboxText?: string;
  checkboxChecked?: boolean;
  placeholderText?: string;
}

@Injectable()
export class MessagesService {

  constructor(public dialog: MatDialog, private _snackBar: MatSnackBar) {
  }

  deleteDialog(data: DialogData): Promise<{res: boolean, data: DialogData}> {
    return new Promise((resolve, reject) => {
      if (!data.okButton) data.okButton = 'OK'
      if (!data.cancelButton) data.cancelButton = ''
      if (!data.deleteText) data.deleteText = ''
      if (!data.checkboxText) data.checkboxText = ''
      if (!data.checkboxChecked) data.checkboxChecked = false
      const dialogRef = this.dialog.open(messagesDialog, {
        width: '500px',
        data: { message: data.message || '', okButton: data.okButton || '', cancelButton: data.cancelButton || '', deleteText: data.deleteText || '', checkboxText: data.checkboxText || '', checkboxChecked: data.checkboxChecked || '', placeholderText: data.placeholderText || '' }
      });
      const sub = dialogRef.afterClosed().subscribe(res => {
        resolve(res)
        sub.unsubscribe()
      })
    })
  }

  showMessage(message, okButton?: string, cancelButton?: string): Promise<{res: boolean, data: DialogData}> {
    return new Promise((resolve, reject) => {
      if (!okButton) okButton = 'OK'
      if (!cancelButton) cancelButton = ''
      const dialogRef = this.dialog.open(messagesDialog, {
        width: '500px',
        data: { message: message, okButton: okButton, cancelButton: cancelButton }
      });
      const sub = dialogRef.afterClosed().subscribe(res => {
        resolve(res)
        sub.unsubscribe()
      })
    })
  }

  showSnackbar(message: string, type?: 'success' | 'error' | 'info', okButton?: string, duration?: number) {
    if (!duration) duration = 5
    this._snackBar.open(message, okButton, {
      duration: duration * 1000,
      panelClass: ['snackbar-' + type]
    });
  }

}

@Component({
  selector: 'messageDialog',
  templateUrl: './messageDialog.component.html',
})
export class messagesDialog {

  deleteTextAnswer: string = ''
  placeholderText: string = ''
  delete: boolean = false

  constructor(public dialogRef: MatDialogRef<messagesDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    if (data.deleteText) {
      if (!data.placeholderText) data.placeholderText = 'Skriv "' + data.deleteText + '" for å slette'
      this.delete = true
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  checkDeleteString() {
    if (!this.delete) return false
    if (this.deleteTextAnswer.toLowerCase().trim() == this.data.deleteText.toLowerCase().trim()) return false
    return true
  }

}