import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { map, throwIfEmpty, first } from 'rxjs/operators';

export interface wishElement {
  id: string,
  title: string,
  imageURL: string,
  url: string,
  type: string,
  index: number,
  boughtAmount: number,
  totalAmount: number,
  amount: number,
  orderID: number,
  groups: {}
}

export interface inviteElement {
  inviteText: string,
  inviteText_both: string,
  inviteText_single: string,
  textfield_1: string,
  textfield_2: string,
  title: string,
  type: string
}

@Component({
  selector: 'app-wish-list-front-page',
  templateUrl: './wish-list-front-page.component.html',
  styleUrls: ['./wish-list-front-page.component.scss']
})

export class WishListFrontPageComponent implements OnInit {

  constructor(
    private afs: AngularFirestore,
    private router: Router
  ) { }

  wishList = []
  currentGroupId_temp = ''
  currentGroupId = ''
  guests: Array<any> = [];
  nameString: string
  loadingText: string;
  inviteType: string;
  pageData: inviteElement;

  getWishList() {
    console.log('groupd ID: ' + localStorage.getItem('invitationId'))
    if (localStorage.getItem('invitationId')) {
      this.currentGroupId = localStorage.getItem('invitationId')
      this.currentGroupId_temp = localStorage.getItem('invitationId')
      this.confirmInvitationId()
    }
    this.afs.collection('pages').doc('IA8ZLWOBJfU8n0SCPzBg').valueChanges().pipe(first()).subscribe((data: inviteElement) => {
      this.pageData = data
    })    
    this.afs.collection('wishList').valueChanges().pipe(map(wishList => {
      wishList.forEach((wish: wishElement) => {
        if (wish.orderID) wish.orderID = +wish.orderID
      })      
      wishList.sort((a, b) => {
        if (a['orderID'] < b['orderID'])
          return -1;
        if (a['orderID'] > b['orderID'])
          return 1;
        return 0;
      });
      return wishList
    })).subscribe(wishList => {
      wishList.forEach((wish: wishElement) => {
        wish['amount'] = 0
        if (!wish['boughtAmount']) wish['boughtAmount'] = 0
        if (!wish.groups) wish.groups = {}
      });
      this.wishList = wishList
    })
  }

  confirmInvitationId() {
    this.loadingText = ''
    this.nameString = ''
    this.currentGroupId = ''
    this.guests = []
    if (this.currentGroupId_temp) {
      this.afs.collection('guests', ref => ref.where('invitationId', '==', this.currentGroupId_temp)).snapshotChanges().pipe(first()).subscribe(guests => {
        if (guests.length < 1) {
          this.loadingText = 'Fant ingen invitasjon med koden ' + this.currentGroupId_temp + '!'
          this.currentGroupId = '';
        } else {
          this.currentGroupId = this.currentGroupId_temp
          guests.forEach(guest => {
            let guestData = guest.payload.doc.data()
            this.guests.push(guestData)
            if (guestData['groupName']) {
              this.nameString = guestData['groupName']
            } else if (guestData['nickName']) {
              this.nameString = guestData['nickName']
            } else {
              this.nameString = guestData['firstname']
            }
          })
        }
      });
    }
  }

  addAmount(wish, i) {
    this.wishList[i].amount = this.wishList[i].amount + 1
  }

  removeAmount(wish, i) {
    if (this.wishList[i].amount > 0) {
      this.wishList[i].amount = this.wishList[i].amount - 1
    }
  }

  confirmAmount(wish: wishElement, i) {
    if (!wish.amount && !wish.groups[this.currentGroupId]) {
      alert('Du må velge et antall først da tøysekopp...')
    } else {
      if (wish.groups[this.currentGroupId]) {
        this.wishList[i].boughtAmount = this.wishList[i].boughtAmount - wish.groups[this.currentGroupId]
        wish.groups[this.currentGroupId] = ''
        wish.amount = 0
        this.afs.collection('wishList').doc(wish.id).update({boughtAmount: wish.boughtAmount, groups: wish.groups})
      } else {
        this.wishList[i].boughtAmount = this.wishList[i].boughtAmount + wish.amount
        wish.groups[this.currentGroupId] = wish.amount
        wish.amount = 0
        this.afs.collection('wishList').doc(wish.id).update({boughtAmount: wish.boughtAmount, groups: wish.groups})
      }
    }
  }

  checkDisabledButtons(wish: wishElement) {
    if (wish.groups[this.currentGroupId]) {
      return true
    } else {
      return false
    }
  }

  checkDisabled(wish: wishElement) {
    if (wish.groups[this.currentGroupId]) {
      return false
    } else if (wish.boughtAmount >= wish.totalAmount) {
      return true
    } else if ((wish.boughtAmount + wish.amount) > wish.totalAmount ) {
      return true
    } else {
      return false
    }
  }

  wishButtonText(wish) {
    if (wish.groups[this.currentGroupId]) {
      return 'angre'
    } else if (wish.boughtAmount >= wish.totalAmount) {
      return 'allerede kjøpt'
    } else {
      return 'Bekreft Kjøp'
    }
  }

  goToWishURL(url) {
    window.open(url, "_blank")
  }

  ngOnInit() {
    this.getWishList();
  }

}
