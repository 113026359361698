import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { AngularFirestore } from '@angular/fire/firestore';
import {MatSort} from '@angular/material/sort';
import * as firebase from 'firebase/app';
import { map } from 'rxjs/operators';

export interface wishElement {
  id: string,
  title: string,
  imageURL: string,
  url: string,
  type: string,
  index: number,
  totalAmount: number,
  boughtAmount: number,
  orderID: number,
  refreshPrice: boolean,
  price: string,
  timestamp: number,
}

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class wishlistComponent implements OnInit {

  wishList = new MatTableDataSource([]);
  columnsToDisplay = ['button', 'title', 'order', 'totalAmount', 'price', 'boughtAmount', 'delete'];
  selection = new SelectionModel<wishElement>(true, []);
  @ViewChild('wishDetailModal',{static: true}) public wishDetailModalChild;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  refreshAll: boolean = false


  currentPageId: string;
  currentWish: wishElement = {
    id: '',
    title: '',
    imageURL: '',
    url: '',
    type: '',
    index: 0,
    boughtAmount: 0,
    totalAmount: 0,
    orderID: 0,
    refreshPrice: false,
    price: '',
    timestamp: null,
  }

  editorConfig = {
    'height': 'auto',
    'minHeight': '100',
    'width': 'auto',
    "toolbar": [
      ["bold", "italic", "underline"],
      // ["fontName", "fontSize", "color"],
      ["justifyLeft", "justifyCenter", "justifyRight"],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
    ]
  }

  constructor(
    public afAuth: AngularFireAuth, 
    private afs: AngularFirestore,
    private router: Router) {
  
  }

  resetAll() {
    this.refreshAll = true;
    var batch = this.afs.firestore.batch();
    this.wishList.data.forEach((wish: wishElement) => {
      var batchRef = this.afs.firestore.collection('wishList').doc(wish.id)
      batch.update(batchRef, {price: '', imageURL: '', title: '', timestamp: null});
    })
    batch.commit();
  }

  getExtDataAll() {
    this.refreshAll = true;
    var batch = this.afs.firestore.batch();
    this.wishList.data.forEach((wish: wishElement) => {
      var batchRef = this.afs.firestore.collection('wishList').doc(wish.id)
      batch.update(batchRef, {refreshPrice: true});
    })
    batch.commit();
  }

  getExtData(wish: wishElement) {
    console.log(wish)
    wish.refreshPrice = true;
    this.currentWish.refreshPrice = true;
    this.currentWish.type = 'xxl'
    this.afs.collection('wishList').doc(this.currentWish.id).set(this.currentWish, {merge: true}).then(() => {
      let sub = this.afs.collection('wishList').doc(wish.id).valueChanges().subscribe((wish: wishElement) => {
        if (wish.refreshPrice == false) {
          this.currentWish.price = wish.price,
          this.currentWish.title = wish.title,
          this.currentWish.imageURL = wish.imageURL
          this.currentWish.timestamp = wish.timestamp
          wish.refreshPrice = false;
          this.currentWish.refreshPrice = false;
          sub.unsubscribe();
        }
      })
    })
  }

  getWishList() {
      this.afs.collection('wishList').valueChanges().pipe(map(wishList => {
        wishList.forEach((wish: wishElement) => {
          if (wish.orderID) wish.orderID = +wish.orderID
        })
        wishList.sort((a, b) => {
          if (a['orderID'] < b['orderID'])
            return -1;
          if (a['orderID'] > b['orderID'])
            return 1;
          return 0;
        });
        return wishList
      })).subscribe(wishList => {
        this.wishList.data = wishList
        this.wishList.sort = this.sort;
        this.selection.clear();
      })
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.wishList.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.wishList.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: wishElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index}`;
  }  

  applyFilter(filterValue: string) {
    this.wishList.filter = filterValue.trim().toLowerCase();
  }

  applyFilterGroup(group) {
    this.wishList.filterPredicate = (data, filter: string): boolean => {
      return data.name.toLowerCase().includes(filter) || data.symbol.toLowerCase().includes(filter) || data.position.toString().includes(filter);
  };
  }

  hideModal() {

  }

  closeModal() {
    this.wishDetailModalChild.hide();
  }

  deleteWish(wish) {
    var melding = confirm('Er du helt sikker på at du vil slette dette ønsket?');
    if (melding == true) {
      this.afs.collection('wishList').doc(wish.id).delete();
    }
  }

  updateOrder(wish) {
    this.afs.collection('wishList').doc(wish['id']).update({orderID: wish.orderID});
  }

  updateType(wish) {
    this.afs.collection('wishList').doc(wish['id']).update({type: wish.type});
  }

  updateSymbol(wish) {
    this.afs.collection('wishList').doc(wish['id']).update({symbol: wish.symbol});
  }

  saveWish() {
    this.currentWish.type = 'xxl'
    this.currentWish.refreshPrice = false
    this.afs.collection('wishList').doc(this.currentWish.id).set(this.currentWish, {merge: true});
    this.wishDetailModalChild.hide();
  }


  openWish(wish) {
    this.currentWish = wish;
    this.wishDetailModalChild.show();
  }

  newWish() {
    let orderID = this.wishList.data.length + 1
    this.currentWish = {
      id: firebase.firestore().collection('wishList').doc().id,
      title: '',
      imageURL: '',
      url: '',
      type: 'xxl',
      index: 0,
      totalAmount: 0,
      boughtAmount: 0,
      orderID: orderID,
      refreshPrice: false,
      price: '',
      timestamp: null
    }
    this.wishDetailModalChild.show();
  }

  ngOnInit() {
    this.getWishList();
  }

}
