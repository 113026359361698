import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SearchPipe_Status'
})
export class SearchPipe_Status implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(msg => {
        if (msg.Status) {
          return msg.Status.search(searchText) !== -1;
        } else {
          if (args.length > 0) {
            return false
          } else {
            return true
          }
        }
      });
    }
  }
}

@Pipe({
  name: 'SearchPipe_user'
})
export class SearchPipe_user implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(person => {
        var name = person.name + ' ' + person.phone + ' ' + person.email
        if (name) {
          return name.search(searchText) !== -1;
        }
      });
    }
  }
}