import { LayoutModule } from '@angular/cdk/layout';
import { registerLocaleData } from '@angular/common';
import localeNb from '@angular/common/locales/nb';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthGuard, AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AngularFirestoreModule } from '@angular/fire/firestore';
// import { AngularFirestoreModule, USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/firestore';
// import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/auth';
// import { NEW_ORIGIN_BEHAVIOR, REGION, USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/functions';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { databaseModule } from './Database';
import { FrontPageModule } from './front-page/front-page.module';
import { inviteModule } from './invite/invite.module';
import { LoginModule } from './login/login.module';
import { MessagesModule } from './services/messages/messages.module';


registerLocaleData(localeNb, 'nb');


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatButtonModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthGuardModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,  
    FrontPageModule,
    AdminModule,
    LoginModule,
    inviteModule,
    databaseModule,
    MessagesModule,
  ],
  providers: [
    AngularFireAuthGuard, 
    {provide: LOCALE_ID, useValue: 'nb'},
    // { provide: USE_FIRESTORE_EMULATOR, useValue: environment.emulator ? ['localhost', 8080] : undefined },
    // { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.emulator || environment.emulator_functions ? ['localhost', 5001] : undefined },
    // { provide: NEW_ORIGIN_BEHAVIOR, useValue: true },
    // { provide: REGION, useValue: 'europe-west2' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
