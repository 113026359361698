import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { messageDatabase } from './services/message.database';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    messageDatabase,
  ]
})

export class databaseModule {}

export { messageDatabase };