import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import {SelectionModel} from '@angular/cdk/collections';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import * as firebase from 'firebase/app';
import { map, first, filter, last } from 'rxjs/operators';
import * as XLSX from 'xlsx'
import { MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { GuestElement } from '../../../interfaces/interfaces';
import { ModalDirective } from 'ngx-bootstrap';

export interface filterType {
  activeFilter?: Array<string>;
  searchString?: string;
}

export interface countObject {
  bride: {
    saturdayTotal: number,
    saturdayTrue: number,
    saturdayFalse: number,
    saturdayChild: number,
    saturdayChildTrue: number,
    accomodationTotal: number,
  },
  groom: {
    saturdayTotal: number,
    saturdayTrue: number,
    saturdayFalse: number,
    saturdayChild: number,
    saturdayChildTrue: number,
    accomodationTotal: number,
  },
  total: {
    saturdayTotal: number,
    saturdayTrue: number,
    saturdayFalse: number,
    saturdayChild: number,
    saturdayChildTrue: number,
    accomodationTotal: number,
  }
}

@Component({
  selector: 'app-guest-list',
  templateUrl: './guest-list.component.html',
  styleUrls: ['./guest-list.component.scss']
})
export class guestListComponent implements OnInit {
  guests: MatTableDataSource<GuestElement> = new MatTableDataSource([]);
  columnsToDisplay = ['button', 'groupId', 'select', 'firstname', 'child', 'phone', 'role', 'invite', 'order', 'day1', 'day2', 'note', 'visitCount'];
  selection = new SelectionModel<GuestElement>(true, []);
  @ViewChild('messagesDetail',{static: true}) public messagesDetailChild;
  @ViewChild('guestDetailModal',{static: true}) public guestDetailModalChild: ModalDirective;
  @ViewChild('noteDetailModal',{static: true}) public noteDetailModalChild: ModalDirective;
  @ViewChild('guestCountModal',{static: true}) public guestCountModalChild;

  activeFilters: Array<string> = []
  
  searchText: string = ''
  filterObject: filterType = {}
  subscription
  isGroup
  isUploading
  uploadedGuests: Array<any> = []
  activeGroup: string;
  currentGuest: GuestElement = {}
  currentGuestRevert

  countObject: countObject = {
    bride: {
      saturdayTotal: 0,
      saturdayTrue: 0,
      saturdayFalse: 0,
      saturdayChild: 0,
      saturdayChildTrue: 0,
      accomodationTotal: 0,
    },
    groom: {
      saturdayTotal: 0,
      saturdayTrue: 0,
      saturdayFalse: 0,
      saturdayChild: 0,
      saturdayChildTrue: 0,
      accomodationTotal: 0,
    },
    total: {
      saturdayTotal: 0,
      saturdayTrue: 0,
      saturdayFalse: 0,
      saturdayChild: 0,
      saturdayChildTrue: 0,
      accomodationTotal: 0,
    }
  }
  
  constructor(
    private afs: AngularFirestore,
    private router: Router
  ) {
    
  }

  toggleFilter(filter: string) {
    if (this.checkFilter(filter)) {
      const i = this.activeFilters.findIndex(f => f == filter)
      this.activeFilters.splice(i, 1)
    } else {
      this.activeFilters.push(filter)
    }
    this.applyFilter(this.searchText)
  }

  checkFilter(filter: string) {
    return this.activeFilters.includes(filter)
  }

  sortData(sort: Sort) { 
    if (!sort.active || sort.direction === '') {
      // this.applications.data = this.applications.data.sort((a,b) => {return this.compare(a.timestamp.getTime(), b.timestamp.getTime(), true)})
      return;
    }
    this.guests.data = this.guests.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        // case 'attending': return this.compare(a.attending.saturday, b.attending.saturday, isAsc);
        // case 'group': return this.compare(a.groups[0].group_name, b.groups[0].group_name, isAsc)
        default: return 0;
      }
    });
  } 

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  resetCountObject() {
    this.countObject = {
      bride: {
        saturdayTotal: 0,
        saturdayTrue: 0,
        saturdayFalse: 0,
        saturdayChild: 0,
        saturdayChildTrue: 0,
        accomodationTotal: 0,
      },
      groom: {
        saturdayTotal: 0,
        saturdayTrue: 0,
        saturdayFalse: 0,
        saturdayChild: 0,
        saturdayChildTrue: 0,
        accomodationTotal: 0,
      },
      total: {
        saturdayTotal: 0,
        saturdayTrue: 0,
        saturdayFalse: 0,
        saturdayChild: 0,
        saturdayChildTrue: 0,
        accomodationTotal: 0,
      }
    }
  }

  runMagic() {
    this.updateGroupCount()
  }

  updateGroupCount() {
    var batch = this.afs.firestore.batch();
    this.guests.data.forEach((guest: GuestElement) => {
      let groupGuests: Array<any> = this.guests.data.filter((g: GuestElement) => g.groupId == guest.groupId)
      var batchRef = this.afs.firestore.collection('guests').doc(guest.id)
      let length
      if (groupGuests.length > 1 && guest.groupId) {
        length = groupGuests.length
      } else {
        length = 1
      }
      if (groupGuests.length > 0) {
        batch.update(batchRef, {groupCount: length});
      }
      console.log(groupGuests)
      console.log(length)
    })
    batch.commit();
  }

  makeCountObject(guests: GuestElement[]) {
    guests.forEach(guest => {
      if (guest.guestOf == 'bride') {
        //Total hvis bare Vielse
        // if (guest.invite == 'opt1') {
        //   this.countObject.bride.fridayTotal++
        //   this.countObject.bride.fridayOnlyTotal++
        // }
        //Total hvis Vielse + Hagefest
        if (guest.invite == 'opt2') {
          // this.countObject.bride.fridayTotal++
          this.countObject.bride.saturdayTotal++
        }
        //Total hvis bare Hagefest
        if (guest.invite == 'opt3') this.countObject.bride.saturdayTotal++
        if (guest.child) this.countObject.bride.saturdayChild++
        if (guest.accomodation) this.countObject.bride.accomodationTotal++
        //Hvis gjesten har svart
        if (guest.answered && guest.attending) {
          //har svart fredag
          // if (guest.attending.friday) {
          //   this.countObject.bride.fridayTrue++ //hvis gjesten kommer
          //   if (guest.child) this.countObject.bride.fridayChild++ //Barn
          // } else if (guest.invite != 'opt3') {
          //   this.countObject.bride.fridayFalse++//hvis gjesten ikke kommer
          // }
          //har svart lørdag
          if (guest.attending.saturday) {
            this.countObject.bride.saturdayTrue++ //hvis gjesten kommer
            if (guest.child) this.countObject.bride.saturdayChildTrue++ //Barn
          } else if (guest.invite != 'opt1') {
            this.countObject.bride.saturdayFalse++ //hvis gjesten ikke kommer
          }
        }
      } else if (guest.guestOf == 'groom') {
        //Total hvis bare Vielse
        // if (guest.invite == 'opt1') {
        //   this.countObject.groom.fridayTotal++
        //   this.countObject.groom.fridayOnlyTotal++
        // }
        //Total hvis Vielse + Hagefest
        if (guest.invite == 'opt2') {
          // this.countObject.groom.fridayTotal++
          this.countObject.groom.saturdayTotal++
        }
        //Total hvis bare Hagefest
        if (guest.invite == 'opt3') this.countObject.groom.saturdayTotal++
        if (guest.child) this.countObject.groom.saturdayChild++
        if (guest.accomodation) this.countObject.groom.accomodationTotal++
        //Hvis gjesten har svart
        if (guest.answered && guest.attending) {
          //har svart fredag
          // if (guest.attending.friday) {
          //   this.countObject.groom.fridayTrue++ //hvis gjesten kommer
          //   if (guest.child) this.countObject.groom.fridayChild++ //Barn
          // } else if (guest.invite != 'opt3') {
          //   this.countObject.groom.fridayFalse++//hvis gjesten ikke kommer
          // }
          //har svart lørdag
          if (guest.attending.saturday) {
            this.countObject.groom.saturdayTrue++ //hvis gjesten kommer
            if (guest.child) this.countObject.groom.saturdayChildTrue++ //Barn
          } else if (guest.invite != 'opt1') {
            this.countObject.groom.saturdayFalse++ //hvis gjesten ikke kommer
          }
        }
      } else if (guest.guestOf == 'both') {
      }
      // this.countObject.total.fridayTotal = this.countObject.bride.fridayTotal + this.countObject.groom.fridayTotal
      this.countObject.total.saturdayTotal = this.countObject.bride.saturdayTotal + this.countObject.groom.saturdayTotal
      // this.countObject.total.fridayOnlyTotal = this.countObject.bride.fridayOnlyTotal + this.countObject.groom.fridayOnlyTotal
      // this.countObject.total.fridayTrue = this.countObject.bride.fridayTrue + this.countObject.groom.fridayTrue
      this.countObject.total.saturdayTrue = this.countObject.bride.saturdayTrue + this.countObject.groom.saturdayTrue
      // this.countObject.total.fridayFalse = this.countObject.bride.fridayFalse + this.countObject.groom.fridayFalse
      this.countObject.total.saturdayFalse = this.countObject.bride.saturdayFalse + this.countObject.groom.saturdayFalse
      // this.countObject.total.fridayChild = this.countObject.bride.fridayChild + this.countObject.groom.fridayChild
      this.countObject.total.saturdayChild = this.countObject.bride.saturdayChild + this.countObject.groom.saturdayChild
      this.countObject.total.saturdayChildTrue = this.countObject.bride.saturdayChildTrue + this.countObject.groom.saturdayChildTrue
      this.countObject.total.accomodationTotal = this.countObject.bride.accomodationTotal + this.countObject.groom.accomodationTotal
    });
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  getGuests(groupId) {
    if (this.subscription) this.subscription.unsubscribe();
    this.guests.data = [];
    this.isGroup = false;
    this.isUploading = false;
    if (groupId) {
      this.isGroup = true
      this.subscription = this.afs.collection('guests').valueChanges().pipe(first()).pipe(map(sections => {
        sections.sort((a, b) => {
          if (a['orderID'] < b['orderID'])
            return -1;
          if (a['orderID'] > b['orderID'])
            return 1;
          return 0;
        });
        return sections
      })).subscribe((guests: GuestElement[]) => {
        // this.guests = new MatTableDataSource(guests);
        this.guests.data = [];
        guests.forEach(guest => {
          if (guest['orderID']) guest.orderID = guest.orderID
          if (guest['groupId'] == groupId) this.guests.data.push(guest)
        })
        // this.guests.data = guests
        this.guests.sort = this.sort;
        this.selection.clear();
      })
    } else {
      this.subscription = this.afs.collection('guests').valueChanges().subscribe((guests: GuestElement[]) => {
        // this.guests = new MatTableDataSource(guests);
        this.guests.data = [];
        this.resetCountObject();
        this.makeCountObject(guests);
        this.guests.data = guests
        this.guests.sort = this.sort;
        this.guests.paginator = this.paginator
        this.selection.clear();
      })
    }
  }

  groupButtonHover(group, hover) {
    if (hover) {
      this.activeGroup = group
    } else {
      this.activeGroup = '';
    }
  }

  changeAttending(key, value) {
    if (!this.currentGuest.attending) this.currentGuest.attending = {
      friday: null,
      saturday: null
    }
    this.currentGuest.answered = true
    this.currentGuest.attending[key] = value
  }

  changeAccomodation(value: boolean) {
    this.currentGuest.answered = true
    this.currentGuest.accomodation = value
  }

  deleteGroup() {
    var melding = confirm('Er du sikker på at du vil slette gruppen?');
    if (melding == true) {
      var batch = this.afs.firestore.batch();
      this.guests.data.forEach(guest => {
        var batchRef = this.afs.firestore.collection('guests').doc(guest.id)
        batch.update(batchRef, {groupId: '', groupName: '', groupCount: 1, invitationId: this.createShortId()});
      })
      batch.commit();
    }
    this.getGuests(false);
  }

  saveGuest(currentGuest: GuestElement) {
    if (currentGuest.orderID) currentGuest.orderID = +currentGuest.orderID
    console.log(currentGuest)
    this.afs.collection('guests').doc(currentGuest.id).set(currentGuest, {merge: true}).then(() => {
      console.log('LAGRET')
    })
    let guestsInGroup: Array<GuestElement> = []
    if (currentGuest.groupId) {
      this.guests.data.forEach((guest: GuestElement) => {
        if (guest.id == currentGuest.id) { //Oppdaterer den endrede gjesten i den lokale listen
          guest = currentGuest;
        }
        if (guest.groupId == currentGuest.groupId) {
          guestsInGroup.push(guest)
        }
      })
      var batch = this.afs.firestore.batch();
      let guestWithGroupName = this.makeGroupNameString(guestsInGroup)
      guestWithGroupName.forEach(guest => {
        // console.log(guest);
        var batchRef = this.afs.firestore.collection('guests').doc(guest.id)
        batch.update(batchRef, {groupName: guest.groupName});
      })
      batch.commit();
    }
    this.guestDetailModalChild.hide();
  }

  getCurrentGuestName() {
    if (this.currentGuest.nickName) {
      return this.currentGuest.nickName
    } else {
      return this.currentGuest.firstname + ' ' + this.currentGuest.lastname
    }    
  }

  goToInvite() {
    console.log(this.currentGuest)
    window.open('https://hannaogperchristian.no/inv/' + this.currentGuest.invitationId, '_blank')
  }

  fileChangeEvent(event: any): void {
      let file = event.target.files[0];
      let fileReader = new FileReader();
      let arrayBuffer
      fileReader.onload = (e) => {
        arrayBuffer = fileReader.result;
        var data = new Uint8Array(arrayBuffer);
        var arr = new Array();
        for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, {type:"binary"});
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];
        var uploadedGuests: Array<any> = XLSX.utils.sheet_to_json(worksheet,{raw:true})
        uploadedGuests.forEach(g => {
          if (g.child) g.child = true
        })
        this.guests.data = uploadedGuests
        this.uploadedGuests = uploadedGuests
        this.isUploading = true;
        alert('Opplasting velykket! Se gjennom at alt ser riktig ut, og deretter trykk "Last opp!"')
      }
      fileReader.readAsArrayBuffer(file);
  }

  downloadGuests() {
    console.log('Eksporterer...')
      let newArray = []
      this.guests.data.forEach(guest => {
        newArray.push({
          fornavn: guest.firstname,
          etternavn: guest.lastname,
          phone: guest.phone ? '0047' + guest.phone : '',
          dudere: this.generateDuDere(guest, false),
          degdere: this.generateDegDere(guest, false),
          invitationId: guest.invitationId,
          rolle: guest.role,
          gruppenavn: guest.groupName ? guest.groupName : guest.firstname,
          // invitert: guest.in
        })
      })
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newArray);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      XLSX.writeFile(workbook, 'gjesteliste.xlsx', { bookType: 'xlsx', type: 'file' });
  }

  generateDuDere(user: GuestElement, capital: boolean) {
    console.log(user);
    if (user.groupCount > 1) {
      if (capital) {
        return 'Dere'
      } else {
        return 'dere'
      }
    } else {
      if (capital) {
        return 'Du'
      } else {
        return 'du'
      }
    }
  }

  generateDegDere(user: GuestElement, capital: boolean) {
    if (user.groupCount > 1) {
      if (capital) {
        return 'Dere'
      } else {
        return 'dere'
      }
    } else {
      if (capital) {
        return 'Deg'
      } else {
        return 'deg'
      }
    }
  }

  uploadGuests() {
    var batch = this.afs.firestore.batch();
    this.uploadedGuests.forEach((guest: GuestElement) => {
      guest.id = firebase.firestore().collection('guests').doc().id
      guest.invitationId = this.createShortId()
      var batchRef = this.afs.firestore.collection('guests').doc(guest.id)
      batch.set(batchRef, guest);
    })
    batch.commit();
    this.isUploading = false
    this.getGuests(false)
  }

  openGuest(guest: GuestElement) {
    this.currentGuest = JSON.parse(JSON.stringify(guest))
    this.currentGuestRevert = JSON.parse(JSON.stringify(guest))
    this.guestDetailModalChild.show();
  }

  openNote(guest: GuestElement) {
    this.currentGuest = JSON.parse(JSON.stringify(guest))
    this.noteDetailModalChild.show()
  }

  addGuest() {
    this.currentGuest = {
      id: firebase.firestore().collection('guests').doc().id,
      invitationId: this.createShortId(),
      index: 0,
      firstname: '',
      lastname: '',
      nickName: '',
      groupName: '',
      groupCount: null,
      name: '',
      phone: null,
      role: '',
      groupId: '',
      hasGroup: false,
      orderID: 0,
      answered: false,
      guestOf: '',
      child: false,
      invite: '',
      visitCount: null,
      note: '',
      attending: {
        friday: null,
        saturday: null,
      }
    }
    this.guestDetailModalChild.show();
  }

  resetVisitCount() {
    var melding = confirm('Er du helt sikker på vil SLETTE besøkstallet på de valgte gjestene??');
    if (melding == true) {
      var batch = this.afs.firestore.batch();
      this.selection.selected.forEach((guest: GuestElement) => {
        var batchRef = this.afs.firestore.collection('guests').doc(guest.id)
        batch.update(batchRef, {visitCount: null});
      })
      batch.commit();
    }
  }

  deleteGuests() {
    var melding = confirm('Er du helt sikker på vil SLETTE de valgte gjestene?? Hvis du har sendt ut invitasjonslinker, så må det sendes på nytt!!');
    if (melding == true) {    
      var batch = this.afs.firestore.batch();
      this.selection.selected.forEach((guest: GuestElement) => {
        var batchRef = this.afs.firestore.collection('guests').doc(guest.id)
        batch.delete(batchRef);
      })
      batch.commit();
    }
  }

  closeModal() {
    this.guestDetailModalChild.hide();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.guests.filteredData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.guests.filteredData.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: GuestElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index}`;
  }  

  applyFilter(filterValue: string) {
    this.searchText = filterValue
    this.filterObject = {
      activeFilter: this.activeFilters
    }
    if (filterValue) {
      this.filterObject.searchString = filterValue.trim().toLowerCase();
    } else {
      this.filterObject.searchString = ''
    }
    this.guests.filter = JSON.stringify(this.filterObject)
  }

  sendSMS() {
    this.messagesDetailChild.newMessage('sms');
  }

  makeGroupNameString(guestArray: GuestElement[]) {
    let guests: Array<GuestElement> = []
    let guestsObject: Object = {}
    let nameString: string = ''
    guestArray.sort((a, b) => {
        if (a['orderID'] < b['orderID'])
          return -1;
        if (a['orderID'] > b['orderID'])
          return 1;
        return 0;
      }).forEach((guest, i) => {
        // console.log(guest.firstname)
        if (guest.nickName) {
          nameString += guest.nickName
        } else {
          nameString += guest.firstname
        }
        // console.log(guestArray.length)
        // console.log(i+1)
        if (guestArray.length == (i+2)) {
          nameString += ' og '
        } else if (guestArray.length > (i+2)) {
          nameString += ', '
        } else {
          // this.nameString += ' ' + guest.lastname
        }
      })
    guestArray.forEach(guest => {
      guest.groupName = nameString
    })
    return guestArray
  }

  createShortId() {
    let groupID = firebase.firestore().collection('guests').doc().id
    return groupID.slice(15,19);
  }

  createGroup() {
    let groupID = this.createShortId()
    let hasGroup = false
    let length = this.selection.selected.length
    this.selection.selected.forEach(guest => {
      if (guest.groupId) {
        hasGroup = true
        guest.hasGroup = true
      } else {
        guest.groupId = groupID
        guest.invitationId = groupID
        guest.groupCount = length
      }
    });
    if (hasGroup) {
      this.selection.selected.forEach(guest => {
        if (!guest.hasGroup) {
          guest.groupId = ''
          guest.groupCount = 1
        }
      })
      alert ('Noen gjester du har valgt har allerede en gruppe..')
    } else {
      var batch = this.afs.firestore.batch();
      let guestWithGroupName = this.makeGroupNameString(this.selection.selected)
      guestWithGroupName.forEach(guest => {
        var batchRef = this.afs.firestore.collection('guests').doc(guest.id)
        batch.update(batchRef, guest);
      })
      batch.commit();
    }
    console.log(this.selection.selected);
  }

  showCount() {
    this.guestCountModalChild.show();
  }

  ngOnInit() {
    this.getGuests(false);
    this.guests.filterPredicate = (data, filter): boolean => {
      let filterObject: filterType = JSON.parse(filter)
      let firstname = false
      let lastname = false
      let groupName = false
      if (data.firstname) firstname = data.firstname.toLowerCase().trim().includes(filterObject.searchString)
      if (data.lastname) lastname = data.lastname.toLowerCase().trim().includes(filterObject.searchString)
      if (data.groupName) groupName = data.groupName.toLowerCase().trim().includes(filterObject.searchString)
      if (filterObject.activeFilter.length > 0) {
        let answered = true
        let attending = true
        let accomodation = true
        let note = true
        if (filterObject.activeFilter.includes('answered')) {
          if (data.answered) {
            answered = true
          } else {
            answered = false
          }
        }
        if (filterObject.activeFilter.includes('attending')) {
          if (data.attending && data.attending.saturday) {
            attending = true
          } else {
            attending = false
          }
        }
        if (filterObject.activeFilter.includes('accomodation')) {
          if (data.accomodation) {
            accomodation = true
          } else {
            accomodation = false
          }
        }
        if (filterObject.activeFilter.includes('note')) {
          if (data.note) {
            note = true
          } else {
            note = false
          }
        }
        return answered && attending && accomodation && note && (firstname || lastname || groupName)
      } else {
        return firstname || lastname || groupName
      }
    }
  }

}
