import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';


@Injectable()
export class messageDatabase {


    receivers: object = {}
    receiverCount: number = 0;
    currentMessage

    constructor(
        private afs: AngularFirestore,
    ){

    }
    
    newMessage(message) {
        console.log(message);
        return this.afs.collection('messages').doc(message.id).set(message);
    }

    getAllMessages() {
        return this.afs.collection('messages').valueChanges();
    }

    getMessage(messageId) {
        return this.afs.collection('messages').doc(messageId)
    }
    
}